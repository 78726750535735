import React from 'react';
import Layout from '../components/layout';
import './index.scss';

const IndexPage = () => (
  <Layout>
    <div className="main main-content">
      <h1 className="name">Henry Salcedo</h1>

      <p className="intro">
        Hello! I'm a <span className="emphasis">Procurement Specialist</span>.
      </p>
      <p className="intro">
        Which really means, I am a negotiator of fruits and vegetables considering <span className="emphasis">cost, quality, food safety, and logistics</span>.
      </p>
      <p className="intro">
        With over <span className="emphasis">35 years of experience</span>, eighteen in the procurement management of salad/fresh slice industry, I know my craft and get things done for my customers.
      </p>
      <p className="intro">
        I'm about becoming a part of your procurement team & <span className="emphasis">driving towards company goals</span>. Finding out what matters to partners is what drives me and keeps me going during challenging times.
      </p>
      <div className="list">
        <p className="intro emphasis">Accomplishments</p>
        <ul className="accomplishments">
          <li>Developed scalable, cost optimized raw apple procurement model.</li>
          <li>Developed procurement model for whole, unpeeled yellow onions considering favorable COGS and McDonald's freshness requirements.</li>
          <li>Developed procurement model for pineapples -- increasing plant efficiencies and waste reduction.</li>
          <li>Successfully managed over <span className="emphasis">$170 million annually</span> worth of produce.</li>
        </ul>
      </div>
      <div className="contact">
        <p className="intro emphasis">Contact Me</p>
        <p>Cell: 520 313 0888</p>
        <p>Email: henry@henrysalcedo.com</p>
      </div>
    </div>
  </Layout>
);

export default IndexPage;
